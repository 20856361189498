import React from "react";
import {
  PWAInstallationButtonContainer,
  PWAInstallationLogo,
  PWAInstallationText,
  PWAInstallationLink
} from "./pwa-installation-button-styles";
import { usePWAInstallationButton } from "./use-pwa-installation-button";

interface PWAInstallationButtonProps {
  isTextButton?: boolean;
}

export const PWAInstallationButton = ({
  isTextButton = false
}: PWAInstallationButtonProps) => {
  const {
    handlePWAInstallationClick,
    buttonText,
    shouldRenderInstallationButton
  } = usePWAInstallationButton();

  if (!shouldRenderInstallationButton) {
    return null;
  }

  if (isTextButton) {
    return (
      <PWAInstallationLink
        id="pwa-installation-text-button"
        onClick={handlePWAInstallationClick}
      >
        {buttonText}
      </PWAInstallationLink>
    );
  }

  return (
    <PWAInstallationButtonContainer
      onClick={handlePWAInstallationClick}
      data-testid="pwa-installation-button"
    >
      <PWAInstallationLogo
        alt="my trip companion logo"
        src="/assets/images/my-tc-logo.png"
      />
      <PWAInstallationText data-testid="pwa-installation-button-text">
        {buttonText}
      </PWAInstallationText>
    </PWAInstallationButtonContainer>
  );
};
