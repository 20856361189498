import React from "react";
import { Analytics } from "@customer_interactions/application-logger";
import {
  FooterContainer,
  FlexColumn,
  RightsReserved,
  Link,
  RightsReservedText,
  FooterListItem
} from "./footer-styles";
import { CookiesSettings } from "../cookies/cookies";
import { PWAInstallationButton } from "../pwa-installation-button";
import { useFooter } from "./use-footer";
import { usePWAInstallationButton } from "../pwa-installation-button/use-pwa-installation-button";

const handleClickOf = (linkName: string) => () => {
  Analytics.sendEvent(
    `[customer_space_landing] ${linkName}-click`,
    "Customer_Space"
  );
};

const handleCookiesSettingsClick = () =>
  Analytics.sendEvent(`[Footer]Preference_center-click`, "Customer_Space");

export const Footer = () => {
  const { footerItems, t } = useFooter();
  const { shouldRenderInstallationButton } = usePWAInstallationButton();

  const appVersion = process.env.REACT_APP_VERSION;
  return (
    <FooterContainer>
      <FlexColumn>
        {footerItems.map(item => (
          <Link
            data-testid={item.testId}
            to={item.url}
            key={item.testId}
            onClick={handleClickOf(item.analyticsId)}
            isExternalService={!!item.external}
          >
            {item.text}
          </Link>
        ))}
        <FooterListItem>
          <CookiesSettings onClick={handleCookiesSettingsClick} />
        </FooterListItem>
        {shouldRenderInstallationButton && (
          <FooterListItem>
            <PWAInstallationButton isTextButton />
          </FooterListItem>
        )}
      </FlexColumn>
      <RightsReserved>
        <RightsReservedText data-testid="rights-reserved-text">
          {t("[footer]rights_text", false, {
            year: new Date().getFullYear(),
            appVersion
          })}
        </RightsReservedText>
      </RightsReserved>
    </FooterContainer>
  );
};
