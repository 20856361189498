import { SagaIterator } from "redux-saga";
import { put, call, takeLatest } from "redux-saga/effects";
import { getURLKey } from "utils/route-utils";
import mergeWith from "lodash/mergeWith";

import { getConfigFileByURL } from "features/configuration/configuration-collaborators";
import { getConfigSuccess } from "features/configuration/actions/configuration-actions";
import { CONFIG_FEATURE } from "features/configuration/actions/configuration-action-types";
import { ConfigModel } from "features/configuration/configuration-types";
import configurationInitialState from "features/configuration/configuration-initial-state";
import { setCurrentLanguageSuccess } from "features/language/actions/language-actions";
import { getDefaultLanguage, isSupportedLanguage } from "utils/language-utils";
import { LanguageParamName } from "constant";
import { getQueryParamValue } from "utils/query-params-utils";
import { isPRADomain } from "utils/variable";
import { loadManifestFile } from "utils/manifest";

const customConfigMergeHelper = (_a: any, b: any) =>
  b && Array.isArray(b) ? b : undefined;

export function* getConfig(): SagaIterator {
  try {
    const brandURL = getURLKey();
    const configuration: ConfigModel = yield call(getConfigFileByURL, brandURL);

    if (isPRADomain("prod")) {
      configuration.partnerTrackingId = "";
    }

    const partnerConfig = mergeWith(
      {},
      configurationInitialState,
      configuration,
      customConfigMergeHelper
    );
    yield put(getConfigSuccess(partnerConfig));

    const manifestId = partnerConfig.manifestId;
    if (manifestId) {
      loadManifestFile(manifestId);
    }

    const languageParam = getQueryParamValue(LanguageParamName);
    const initialLanguage =
      !!languageParam && isSupportedLanguage(configuration, languageParam)
        ? `${languageParam}_${partnerConfig.partnerId}`
        : getDefaultLanguage(partnerConfig);

    yield put(setCurrentLanguageSuccess(initialLanguage));
  } catch (error) {
    // TODO: Error handling
    console.log(error);
  }
}

export default function* main() {
  yield takeLatest(CONFIG_FEATURE.INIT, getConfig);
}
