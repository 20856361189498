import React from "react";
import { ToggleDebugModeContainer } from "./toggle-debug-mode-styles";
import { useDebugMode } from "./use-debug-mode";

export const ToggleDebugMode = () => {
  const { isDebugMode, isDebuggable, toggleDebugMode } = useDebugMode();

  if (!isDebuggable) {
    return null;
  }

  return (
    <ToggleDebugModeContainer>
      <label htmlFor="debug-mode-checkbox">Debug Translations</label>

      <input
        type="checkbox"
        id="debug-mode-checkbox"
        onClick={toggleDebugMode}
        defaultChecked={isDebugMode}
      />
    </ToggleDebugModeContainer>
  );
};
