import { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Analytics } from "@customer_interactions/application-logger";
import { useTheme } from "styled-components";

import { getPageViewEventData } from "features/analytics-events/get-page-view-event-data";
import {
  selectConfiguration,
  selectUseAlternativeHomepage,
  selectUseCustomLanguageInFAQ
} from "features/configuration/selectors/configuration-selectors";
import {
  selectCurrentLanguage,
  selectCustomLanguage
} from "features/language/selectors/language-selector";
import { useScriptLoader } from "hooks/use-script-loader";
import { trackSearchEvent } from "features/analytics-events/track-search-event";
import { useDebugMode, LANGUAGE_DEBUG_MODE_CODE } from "features/debug-mode";

const trackers = [process.env.REACT_APP_GLOBAL_GA_TRACKING_KEY];

export const useFaqs = () => {
  const store = useStore();
  const theme = useTheme();
  const { isLoaded, error } = useScriptLoader(
    process.env.REACT_APP_FAQS_WC_URL!,
    "faqs"
  );
  const { partnerTrackingId } = useSelector(selectConfiguration);
  const useAlternativeHomepage = useSelector(selectUseAlternativeHomepage);
  const { isAuthenticated } = useAuth0();
  const useCustomLanguageInFAQ = useSelector(selectUseCustomLanguageInFAQ);
  const { isDebugMode } = useDebugMode();

  let currentCustomLanguage;

  const languageForMTC = useSelector(
    selectCustomLanguage({
      includeCountryCode: true,
      includePartnerId: useCustomLanguageInFAQ
    })
  );

  const languageForRoutingPage = useSelector(selectCurrentLanguage);

  if (useCustomLanguageInFAQ && useAlternativeHomepage) {
    currentCustomLanguage = languageForRoutingPage;
  } else {
    currentCustomLanguage = languageForMTC;
  }

  currentCustomLanguage = isDebugMode
    ? LANGUAGE_DEBUG_MODE_CODE
    : currentCustomLanguage;

  const handleFaqsChangePath = (e: CustomEvent) => {
    e.stopImmediatePropagation();
    const { name, category } = e.detail.section;
    const pageViewEventData = getPageViewEventData({
      pageName: name,
      isLoggedIn: isAuthenticated,
      state: store.getState(),
      serviceCategory: category,
      isRoutingPage: useAlternativeHomepage
    });

    if (pageViewEventData) {
      Analytics.sendEventData(pageViewEventData);
    }
  };

  const handleFaqsSearchEvent = (e: CustomEvent) => {
    e.stopImmediatePropagation();
    trackSearchEvent("faq");
  };

  useEffect(() => {
    if (partnerTrackingId) {
      trackers.push(partnerTrackingId);
    }
  }, [partnerTrackingId]);

  useEffect(() => {
    document.addEventListener(
      "faqs-page-displays",
      handleFaqsChangePath as EventListener
    );

    document.addEventListener(
      "faqs-search",
      handleFaqsSearchEvent as EventListener
    );

    return () => {
      document.removeEventListener(
        "faqs-page-displays",
        handleFaqsChangePath as EventListener
      );

      document.removeEventListener(
        "faqs-search",
        handleFaqsSearchEvent as EventListener
      );
    };
  }, []);

  return {
    error,
    isLoaded,
    theme,
    currentCustomLanguage,
    partnerTrackingId,
    trackers
  };
};
